<template>
    <r-e-dialog title="添加租房合同" :visible.sync="addDialogVisible" show-footer top="10vh" width="700px"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel" :footerBtnName="footerBtnName">
        <el-form ref="formPublish" label-width="125px" size="small" :model="contractData" :rules="rules">
            <div class="el-descriptions__header">
                <div class="el-descriptions__title">
                    <div data-v-037521bc="" class="title">承租人信息</div>
                </div>
            </div>
            <div class="flex justify-between">
                <el-form-item label="证件号码" prop="leasorIdcard" label-width="100px">
                    <div class="flex justify-between" style="width: 550px">
                        <el-input type="text" v-model="contractData.leasorIdcard" style="width: 480px" :maxlength="18"
                                  placeholder="请填写租客证件号码"/>
                        <el-button type="primary" @click="getLeasorData(contractData.leasorIdcard)">查询</el-button>
                    </div>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item ref="leasorType" label="承租人类型" prop="leasorType" label-width="100px">
                    <el-select v-model="contractData.leasorType" style="width: 200px" @change="typeChange"
                               placeholder="请选择">
                        <el-option label="企业" value="法人"/>
                        <el-option label="个人" value="个人"/>
                    </el-select>
                </el-form-item>
                <el-form-item ref="idType" label="证件类型" prop="idType">
                    <el-select v-model="contractData.idType" @change="idTypeChange" style="width: 200px"
                               placeholder="请选择">
                        <el-option label="税号" value="税号"/>
                        <el-option label="身份证" value="身份证"/>
                        <el-option label="军官证" value="军官证"/>
                        <el-option label="驾驶证" value="驾驶证"/>
                        <el-option label="户口簿" value="户口簿"/>
                        <el-option label="其他" value="其他"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="名称" prop="leasorName" label-width="100px">
                    <el-input type="text" v-model="contractData.leasorName" placeholder="请填写租客姓名"/>
                </el-form-item>
                <el-form-item label="手机号码" prop="leasorPhone">
                    <el-input type="text" v-model="contractData.leasorPhone" :maxlength="13" placeholder="请填写租客手机号码"/>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="紧急联系人"  label-width="100px">
                    <el-input type="text" v-model="contractData.contactName" placeholder="请填写紧急联系人"/>
                </el-form-item>
                <el-form-item label="紧急联系人电话">
                    <el-input type="text" v-model="contractData.contactPhone" :maxlength="13" placeholder="请填写紧急联系人电话"/>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="身份证地址" label-width="100px">
                    <el-input type="text" v-model="contractData.addressId" placeholder="请填写身份证地址"/>
                </el-form-item>
                <el-form-item label="邮箱">
                    <el-input type="text" v-model="contractData.eMail" placeholder="请填写邮箱"/>
                </el-form-item>
            </div>
            <div class="el-descriptions__header">
                <div class="el-descriptions__title">
                    <div data-v-037521bc="" class="title">合同信息</div>
                </div>
            </div>
            <div class="flex justify-between">
                <el-form-item label="经办人" label-width="100px">
                    <!-- 2023年9月8号下午14:21分，天气晴，本人陈守亮遵纪守法，根据需求添加了经办人和管理人！ -->
                    <el-input type="text" v-model="contractData.manager" @change="$forceUpdate()" placeholder="请填写经办人"/>
                </el-form-item>
                <el-form-item label="管理人" >
                    <el-input type="text" v-model="contractData.keeper" @change="$forceUpdate()" placeholder="请填写管理人"/>
                </el-form-item>
            </div>

            <div class="flex justify-between">
                <el-form-item label="签约时间" label-width="100px">
                    <el-date-picker v-model="contractData.signDate" type="date" format="yyyy-MM-dd" style="width: 200px"
                                    value-format="yyyy-MM-dd" placeholder="选择签约时间" @change="$forceUpdate()"/>
                </el-form-item>
<!--              2024-1-5-新增付款方式-陈守亮-->
              <el-form-item label="付款方式" label-width="100px">
                <el-input v-model="showFormAmount.paymentMethodsName" placeholder="付款方式" disabled v-loading="loading"/>
              </el-form-item>
            </div>
            <div class="flex justify-between">

                <el-form-item label="押金" label-width="100px">
                    <el-input v-model="showFormAmount.depositAmount" placeholder="请填写押金" disabled v-loading="loading"/>
                </el-form-item>
              <el-form-item label="月租">
                <el-input v-model="showFormAmount.rent" placeholder="请填写月租" disabled v-loading="loading"/>
              </el-form-item>
<!--                <el-form-item label="服务费" v-if="isBzfOk">-->
<!--                    <el-input v-model="showFormAmount.serviceAmount" placeholder="请填写服务费" disabled v-loading="loading"/>-->
<!--                </el-form-item>-->
            </div>
            <div class="flex justify-between" v-if="isBzfOk">
                <el-form-item label="是否生成账单" label-width="100px">
                    <el-radio-group v-model="contractData.bill">
                        <el-radio-button :label="true">是</el-radio-button>
                        <el-radio-button :label="false">否</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否人脸认证" label-width="100px" v-if="contractData.leasorType === '个人'">
                    <div style="width: 200px">
                        <el-radio-group v-model="contractData.face">
                            <el-radio-button :label="true">是</el-radio-button>
                            <el-radio-button :label="false">否</el-radio-button>
                        </el-radio-group>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="合同周期" label-width="100px" prop="endDate">
                    <el-date-picker type="daterange" v-model="zq" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="人脸头像" v-if="contractData.leasorType === '个人'" prop="faceInfo" label-width="100px">
                    <upload-avatar @on-success="faceInfoUploadSuccess" :uuid="contractData.faceInfo"/>
                </el-form-item>
                <div  v-if="contractData.leasorType === '法人'"
                              label-width="100px" style="display:flex">
                              <div style="margin-left:30px;margin-right:10px"> <span style="color:red">*</span>营业执照</div>
                              <upload-pictureCard :uuidList="imgList" :limit="9" @on-success="handleSuccess" @on-remove="handleRemove" />
                    <!-- <upload-avatar @on-success="businessLicenseUploadSuccess" :uuid="contractData.businessLicense"  :showTip="false" :limit="5"/> -->
                </div>

            </div>
            <div class="flex" v-if="contractData.leasorType === '个人'">
                <el-form-item label="身份证人脸面" prop="frontIdcard" label-width="100px">
                    <upload-avatar @on-success="frontIdcardUploadSuccess" :uuid="contractData.frontIdcard"/>
                </el-form-item>
                <el-form-item label="身份证国徽面" prop="reverseIdcard" label-width="100px">
                    <upload-avatar @on-success="reverseIdcardUploadSuccess" :uuid="contractData.reverseIdcard"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {setUserContract, isbzf, getBzfAmount, hetongshengpiGET,} from "@/api/house-resource";
import {getLeasorDataByCardId} from "@/api/tenant-management";
import {getSignAmount, bzfCreate, getIsGregorin,getContractElectType} from "@/api/contract";
import {timeFormat, numberFormat, ParseFloatFormat, ParseIntFormat, getMonthDiff} from "@custom/index";
import {MessageError, MessageSuccess} from "@custom/message";
import uploadAvatar from "@/components/Upload/upload-avatar";
import uploadPictureCard from "@/components/Upload/upload-picture-card";

export default {
    name: "dialog-add-contract",
    data() {
        return {
            footerBtnName:['取消','审批'],
            addDialogVisible: false,
            imgList: [],
            contractData: {
                leasorIdcard: null,
                leasorType: null,
                idType: null,
                leasorName: null,
                leasorPhone: null,
                contactName: null,
                contactPhone: null,
                addressId: null,
                eMail: null,
                signDate: null,
                rent: 0,
                depositAmount: 0,
                serviceAmount: 0,
                bill: true,
                face: true,
                faceInfo: null,
                frontIdcard: null,
                reverseIdcard: null,
                businessLicense: null,
                manager: null,
                keeper: null,
                startDate: null,
                endDate: null,
                paymentMethodsName:null
            },
            rules: {
                leasorName: [{required: true, message: "承租人不能为空", trigger: "blur"}],
                leasorPhone: [{required: true, message: "承租人电话不能为空", trigger: "blur"}],
                leasorIdcard: [{required: true, message: "证件号码不能为空", trigger: "blur"}],
                contactName: [{required: true, message: "紧急联系人不能为空", trigger: "blur"}],
                contactPhone: [{required: true, message: "紧急联系人电话不能为空", trigger: "blur"}],
                leasorType: [{required: true, message: "请选择承租人类型", trigger: "blur"}],
                idType: [{required: true, message: "请选择证件类型", trigger: "blur"}],
                manager: [{required: true, message: "经办人不能为空", trigger: "blur"}],
                keeper: [{required: true, message: "管理人不能为空", trigger: "blur"}],
                faceInfo: [{required: true, message: "请上传人脸头像", trigger: "blur"}],
                frontIdcard: [{required: true, message: "请上传身份证正面", trigger: "blur"}],
                reverseIdcard: [{required: true, message: "请上传身份证反面", trigger: "blur"}],
                businessLicense: [{required: true, message: "请上传营业执照", trigger: "blur"}],
                endDate: [{required: true, message: "请选择租赁周期", trigger: "blur"}],
            },
            zq: [],

            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            },

            loading: false,

            isBzfOk: null,

            showFormAmount: {
                rent: null,
                depositAmount: null,
                serviceAmount: null,
            }
        };
    },
    components: {uploadAvatar,uploadPictureCard},
    props: {
        currentHouse: {
            type: Object,
            default: () => ({}),
        },
        zUuid: {
            type: String,
            default: null,
        },
    },
    methods: {
        // 打开添加合同弹框
        async openDialog() {
            let that = this;
            //重置租期
            that.zq = [];
            // 保存旧数据
            let oldContractData = that.contractData;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            //结构房间uuid和租户uuid
            const {apartmentUuid, leasorUuid} = that.currentHouse;
            console.log('leasorUuid',leasorUuid);
            console.log('that.currentHouse',that.currentHouse);
            let uuid = that.zUuid || apartmentUuid;
            // 查询当前房间是否是保租房
            const re = await isbzf(uuid);
            //保存结果
            that.isBzfOk = re.info;
            //查询房间金额
            let ApartmentData = await getSignAmount({apartmentUuid: uuid});
            if (ApartmentData) {
                let {info} = ApartmentData;
              console.log(info)
                // 解构金额
                let {
                    bone: depositAmount, deposit, displayAmount: rent, paymentMethod, rentAmount, rentComment,
                    tipAmount: serviceAmount, tipComment, totalAmount, totalComment, totalTip,paymentMethodsName
                } = info;
                // alert(paymentMethodsName)
                //转换金额
                depositAmount = ParseFloatFormat(depositAmount);
                rent = ParseFloatFormat(rent);
                serviceAmount = ParseFloatFormat(serviceAmount);
                deposit = ParseFloatFormat(deposit);
                rentAmount = ParseFloatFormat(rentAmount);
                totalTip = ParseFloatFormat(totalTip);
                totalAmount = ParseFloatFormat(totalAmount);
                that.showFormAmount.rent = numberFormat(rent, 2, '.', '');
                that.showFormAmount.depositAmount = numberFormat(depositAmount, 2, '.', '');
                that.showFormAmount.serviceAmount = numberFormat(serviceAmount, 2, '.', '');
                // 打包数据，并赋值
                that.contractData = {
                    ...oldContractData, depositAmount, rent, serviceAmount, deposit, rentAmount, totalTip, totalAmount,
                    rentComment, tipComment, totalComment, paymentMethod,paymentMethodsName
                };
              that.showFormAmount.paymentMethodsName = that.contractData.paymentMethodsName
            }

            //初始化 签约时间 房间uuid 租户uuid
            that.contractData.signDate = timeFormat(new Date()); //签约时间
            that.contractData.apartmentUuid = uuid; //房源uuid
            that.contractData.leasorUuid = leasorUuid; //承租人uuid

            loading.close();
            that.addDialogVisible = true;
        },

        //承租人类型切换事件
        typeChange(value) {
            //2022/04/02 王江毅 承租人类型是法人证件类型就是税号,是个人证件类型默认身份证
            if (value === "法人") {
                this.contractData.idType = "税号";
                this.contractData.face = false;
            } else {
                this.contractData.idType = "身份证";
            }
            this.contractData.type = value;
            //清除校验
            this.$refs["leasorType"].clearValidate();
            this.$refs["idType"].clearValidate();
        },

        //证件类型切换事件
        idTypeChange(value) {
            //2022/04/02 王江毅 证件类型是税号承租人类型就是法人,其他类型承租人类型就是个人
            if (value === "税号") {
                this.contractData.leasorType = "法人";
            } else {
                this.contractData.leasorType = "个人";
            }
            this.contractData.idType = value;
            //清除校验
            this.$refs["leasorType"].clearValidate();
            this.$refs["idType"].clearValidate();
        },

        // 租户查询回显方法
        async getLeasorData(value) {
            let that = this;
            //调用接口查询租户
            let leasorDataRes = await getLeasorDataByCardId({cardId: value});
            if (leasorDataRes) {
                //解构出租户数组
                let {list = []} = leasorDataRes;
                //判断数量是否大于0，大于0表示存在
                if (list.length > 0) {
                    //解构出租户姓名，用于查询租户
                    let {leasorName} = that.contractData;
                    //判断数组长度，长度为1，直接那第一个，不唯一，通过租户名筛选
                    let leasorData = list.length === 1 ? list[0] : list.find((item) => item.name === leasorName);
                    //解构租户信息
                    let {
                        name, tel1, contactName, contactPhone, type, idType, uuid, faceInfo, frontIdcard, reverseIdcard
                    } = leasorData;
                    //赋值回显数据
                    this.contractData.leasorUuid = uuid;
                    this.contractData.leasorName = name;
                    this.contractData.leasorPhone = tel1;
                    this.contractData.contactName = contactName;
                    this.contractData.contactPhone = contactPhone;
                    this.contractData.leasorType = type;
                    this.contractData.idType = idType ? idType : type === "个人" ? "身份证" : "税号";
                    if (type === '个人') {
                        that.contractData.faceInfo = faceInfo;
                        that.contractData.frontIdcard = frontIdcard;
                        that.contractData.reverseIdcard = reverseIdcard;
                    }
                    if (type === '法人') {
                        that.contractData.businessLicense = faceInfo;
                    }
                    //清空表单校验
                    this.$refs["formPublish"].clearValidate();
                }
            }
        },

        // 点击添加合同弹框事件
        clickSubmit() {
            let that = this;
            const {isBzfOk} = that;
            //表单校验
            this.$refs["formPublish"].validate(async (valid) => {
                if (valid) {
                    //校验租赁周期不能为空
                    if (this.zq === null || this.zq.length === 0) {
                        MessageError("合同周期不能为空");
                        return;
                    }
                    console.log('this.imgList',this.imgList);
                    if(that.contractData.leasorType === '法人'){
                      if (this.imgList === null || this.imgList.length === 0) {
                        MessageError("请上传营业执照");
                        return;
                      }
                    }

                    const [startTime, endTime] = that.zq;
                    //转换周期为月数
                    const monthNumber = getMonthDiff(startTime, endTime);
                    //判断租期月数是否是6-36个月
                    if (monthNumber < 6 || monthNumber > 36) {
                        MessageError("必须是6个月起租，最长不能超过3年");
                        return;
                    }
                    //调用查看合同到期时间在春节前后
                    that.getIsGregorin();
                    /*// 判断当前房间是不是富航房间, 不是富航房间需要判断租户类型是否是个人
                    if (isBzfOk) {
                        //调用查看合同到期时间在春节前后
                        that.getIsGregorin();
                    } else {
                        //获取用户类型
                        const {leasorType} = that.contractData;
                        if (leasorType === "个人") {
                            //查看合同到期时间在春节前后
                            that.getIsGregorin();
                        } else {
                            //查看合同到期时间在春节前后
                            that.getIsGregorin();
                        }
                    }*/
                }
            });
        },

        //查看合同到期时间在春节前后方法
        getIsGregorin() {
            let that = this;
            //解构开始时间，结束时间，房间uuid
            let {startDate, endDate, apartmentUuid} = that.contractData;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            //通过结束时间调用接口查询是否在春节前后
            getIsGregorin({dateTime: endDate}).then(({info}) => {
                //判断是否在春节前后，在春节前后提醒，不在不提醒
                if (info) {
                    const message = "您选择的合同到期时间在春节前后，请确认选择？";
                    const title = "提示";
                    const options = {confirmButtonText: "确定", cancelButtonText: "取消", type: "warning",};
                    this.$confirm(message, title, options).then(() => {
                        //调用方法获取付款明细金额
                        that.getApartmentAmount(startDate, endDate, apartmentUuid);
                    }).catch(() => {
                    });
                } else {
                    //调用方法获取付款明细金额
                    that.getApartmentAmount(startDate, endDate, apartmentUuid);
                }
            }).finally(() => loading.close());
        },

        //获取付款明细金额方法
        getApartmentAmount(startDate, endDate, apartmentUuid) {
            let that = this;
            const {isBzfOk} = that;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});

            // 判断当前房间是不是富航房间,不同房间走不通房间接口
            if (isBzfOk) {
                // 调用接口获取富航房间金额
                getSignAmount({apartmentUuid, startDate, endDate}).then(({info}) => {
                    //调用方法处理富航房间金额并展示
                    that.setUserContract(info);
                }).finally(() => loading.close());
            } else {
                // 调用接口获取保租房金额
                getBzfAmount({apartmentUuid, startDate, endDate}).then(({info}) => {
                    //调用方法处理保租房金额并展示
                    that.exhibitionBzfAmount(info);//昇达签约没有第二种类型
                }).finally(() => loading.close());
            }
        },

        //富航公寓付款明细展示
        setUserContract(data) {
            let that = this;
            //解构数据
            let {paymentMethod, totalAmount, totalComment, rentComment, tipComment,} = data;
            //转换金额
            totalAmount = numberFormat(ParseFloatFormat(totalAmount));
            //提示框展示金额HTML样式
            let htmlText = `<div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">月租金</div>
                                    <div style="width: 300px;padding-left: 20px;">${numberFormat(ParseFloatFormat(data.displayAmount))}元/月</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">服务费</div>
                                    <div style="width: 300px;padding-left: 20px;">${numberFormat(ParseFloatFormat(data.tipAmount))}元/月</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">收款周期</div>
                                    <div style="width: 300px;padding-left: 20px;">${paymentMethod}</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">押金</div>
                                    <div style="width: 300px;padding-left: 20px;">${numberFormat(ParseFloatFormat(data.bone))}元</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">租金备注:</div>
                                    <div style="width: 300px;padding-left: 20px;white-space: normal;overflow: auto;word-break: break-all;"> ${rentComment}</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">服务费备注:</div>
                                    <div style="width: 300px;padding-left: 20px;white-space: normal;overflow: auto;word-break: break-all;">${tipComment}</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">合计</div>
                                    <div style="width: 300px;padding-left: 20px;">${totalAmount}元</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">合计备注:</div>
                                    <div style="width: 300px;padding-left: 20px;white-space: normal;overflow: auto;word-break: break-all;">${totalComment}</div>
                                </div>
                            </div>`;

            //提示付款明细
            this.$alert(htmlText, "付款明细", {dangerouslyUseHTMLString: true}).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                // 解构数据
                let {
                    leasorName, leasorPhone, leasorIdcard, leasorType, apartmentUuid, startDate, endDate, signDate,
                    contactName, contactPhone, bill, addressId, eMail, face, rent, depositAmount, serviceAmount,
                    faceInfo, frontIdcard, reverseIdcard, manager, keeper,
                } = that.contractData;
                //金额转换
                rent = ParseIntFormat(rent);
                depositAmount = ParseIntFormat(depositAmount);
                serviceAmount = ParseIntFormat(serviceAmount);
                                // 获取退房图片
                let businessLicense = that.imgList.join(";");
                console.log('businessLicense',businessLicense);
                // 封装数据
                const paramData = {
                    leasorName, leasorPhone, leasorIdcard, leasorType, faceInfo, frontIdcard, reverseIdcard,
                    apartmentUuid, startDate, endDate, payDate: timeFormat(new Date()), signDate, addressId,
                    paymentMethodCode: 500003, signUrl: "", contactName, contactPhone, eMail, bill, roommates: [],
                    face, rent, depositAmount, serviceAmount, businessLicense, manager, keeper,
                };
                // 调用接口，传递数据，创建系统合同
                setUserContract(paramData).then((res) => {
                    //解构返回数据
                    let {info: {data},} = res;
                    //判断data有没有数据，有数据拿出e签宝签署url
                    if (data) {
                        //解构出e签宝签署url
                        const {url} = data;
                        //新界面打开
                        let tempwindow = window.open("", "_blank");
                        //新界面更换地址
                        tempwindow.location = url;
                    }
                    MessageSuccess("生成合同成功");
                    //调用方法，关闭添加合同弹框
                    that.clickCancel(true);
                }).finally(() => loading.close());
            }).catch(() => {
            });
        },

        //保租房付款明细展示
        exhibitionBzfAmount(data) {
            let that = this;
            //解构数据
            let {paymentMethod, totalAmount, totalComment, rentComment} = data;
            //转换金额
            totalAmount = numberFormat(ParseFloatFormat(totalAmount));
            //提示框展示金额HTML样式
            let htmlText = `<div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">月租金</div>
                                    <div style="width: 300px;padding-left: 20px;">${numberFormat(ParseFloatFormat(data.displayAmount))}元/月</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">收款周期</div>
                                    <div style="width: 300px;padding-left: 20px;">${paymentMethod}</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">押金</div>
                                    <div style="width: 300px;padding-left: 20px;">${numberFormat(ParseFloatFormat(data.bone))}元</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">租金备注:</div>
                                    <div style="width: 300px;padding-left: 20px;white-space: normal;overflow: auto;word-break: break-all;"> ${rentComment}</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">合计</div>
                                    <div style="width: 300px;padding-left: 20px;">${totalAmount}元</div>
                                </div>
                                <div style="display: flex">
                                    <div style="width: 88px;text-align: right">合计备注:</div>
                                    <div style="width: 300px;padding-left: 20px;white-space: normal;overflow: auto;word-break: break-all;">${totalComment}</div>
                                </div>
                            </div>`;
            //提示付款明细
            that.$alert(htmlText, "付款明细", {dangerouslyUseHTMLString: true}).then(() => {
                //调用保租房创建合同方法
                that.bzfCreateContract();

            }).catch((action) =>{
              console.log("调用保租房创建合同方法")
              this.$message({type: "info", message: action === "cancel" ? "放弃保存并离开页面" : "停留在当前页面",})

                }
            );
        },

        //月租金查询
        getSignAmount() {
            const that = this;
            //解构出房间uuid、开始时间，结束时间
            const {apartmentUuid} = this.currentHouse;
            const uuid = that.zUuid || apartmentUuid;
            const {startDate, endDate} = this.contractData;
            //开启加载状态
            that.loading = true;
            //调用接口查询租金
            getSignAmount({apartmentUuid: uuid, startDate, endDate}).then((res) => {
                //解构数据
                let {info = {}} = res;
                info = info || {};
                let {displayAmount = 0, bone = 0, tipAmount = 0} = info;
                displayAmount = displayAmount || 0;
                bone = bone || 0;
                tipAmount = tipAmount || 0;
                //金额转换并赋值回显
                that.contractData.rent = ParseFloatFormat(displayAmount);
                that.contractData.serviceAmount = ParseFloatFormat(tipAmount);
                that.contractData.depositAmount = ParseFloatFormat(bone);
                that.showFormAmount.rent = numberFormat(ParseFloatFormat(displayAmount), 2, '.', '');
                that.showFormAmount.depositAmount = numberFormat(ParseFloatFormat(bone), 2, '.', '');
                that.showFormAmount.serviceAmount = numberFormat(ParseFloatFormat(tipAmount), 2, '.', '');
                //关闭加载状态
                that.loading = false;
            });
        },

        //保租房创建合同方法
        bzfCreateContract() {
          // debugger;
            let that = this;
            // 解构数据
            let {
                leasorName, leasorPhone, leasorIdcard, leasorType, apartmentUuid, startDate, endDate, signDate,
                contactName, contactPhone, bill, addressId, eMail, face, rent, depositAmount, serviceAmount, faceInfo,
                frontIdcard, reverseIdcard, manager, keeper,
            } = that.contractData;
            // 获取退房图片
            let businessLicense = that.imgList.join(";");
            console.log('businessLicense',businessLicense);
          console.log(that.contractData)
            //金额转换
            rent = ParseIntFormat(rent);
            depositAmount = ParseIntFormat(depositAmount);
            serviceAmount = ParseIntFormat(serviceAmount);
            //清楚空格
            if(leasorPhone)leasorPhone = leasorPhone.trim();
            //存在就清空
            if(contactPhone)contactPhone = contactPhone.trim();
            if(leasorIdcard)leasorIdcard = leasorIdcard.trim();
            //封装数据
            const paramData = {
                leasorName, leasorPhone, leasorIdcard, leasorType, apartmentUuid, startDate, endDate,
                payDate: timeFormat(new Date()), signDate, paymentMethodCode: 500003, signUrl: "", contactName,
                contactPhone, eMail, addressId, bill, roommates: [], face, rent, depositAmount, serviceAmount, faceInfo,
                frontIdcard, reverseIdcard, businessLicense, manager, keeper,
            };
          console.log(paramData)
            const loadingOptions = that.loadingOptions;
          console.log(that.contractData.leasorType)
            //判断租户类型走不同接口，创建系统合同
            if (leasorType === "个人") {
              console.log('个人')

                const h = that.$createElement;
                that.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('p', {style: 'font-size: 20px'}, '请选择合同签约方式 '),
                        h('p', {style: 'color: red'}, '线上签约是签约电子合同，线下签约是签约纸质合同')
                    ]),
                    showClose: false,
                    showCancelButton: true,
                    showConfirmButton: true,
                    closeOnPressEscape: false,
                    closeOnClickModal: false,
                    center: true,
                    cancelButtonText: '线下签约',
                    confirmButtonText: '线上签约'
                }).then(action => {
                    paramData.isCreateElectContract = true;
                    console.log("线上", paramData);
                    let loading = that.$loading({...loadingOptions});
                    //调用接口创建保租房个人系统合同
                    // hetongshengpiGET
                  // bzfCreate
                    // /api/new/contract/bzf/create
                  hetongshengpiGET(paramData).then((res) => {
                        // console.log(res,'新签约审批')
                    MessageSuccess("提交审批成功");
                    that.clickCancel(true);
                    // 解构返回数据，获取合同uuid
                    // let {info} = res;
                    // console.log('info.apartment.uuid',info.apartment.uuid);
                    // //赋值uuid
                    // paramData.uuid = info.uuid;
                    // paramData.contractUuid = info.uuid;
                    // //保存数据到localStorage
                    // localStorage.setItem("paramData", JSON.stringify(paramData));
                    // let loading = that.$loading({...loadingOptions});
                    // // 获取电子合同类型  0林里桥整租 1伶俐人才孵化基地整租
                    // getContractElectType({apartmentUuid:info.apartment.uuid}).then((res) => {
                    //     let {info} = res;
                    //     if(info.electContractType === 0){
                    //         const newWindow = this.$router.resolve({path: "/test-canvas"});
                    //         //新界面打开
                    //         window.open(newWindow.href, "_blank");
                    //     }else if (info.electContractType === 1){
                    //         const newWindows = this.$router.resolve({path: "/test-canvas-talent"});
                    //         //新界面打开
                    //         window.open(newWindows.href, "_blank");
                    //     }
                    //     MessageSuccess("生成合同成功");
                    //     //调用方法，关闭添加合同弹框
                    //     that.clickCancel();
                    // }).finally(() => loading.close());
                    //新建路由
                    // const newWindows = this.$router.resolve({path: "/test-canvas"});
                    // const newWindows = this.$router.resolve({path: "/test-canvas-talent"});
                    // //新界面打开
                    // window.open(newWindows.href, "_blank");
                    //调用方法，关闭添加合同弹框
                    // that.clickCancel();
                        // 解构返回数据，获取合同uuid
                        // let {info} = res;
                        // //赋值uuid
                        // paramData.uuid = info.uuid;
                        // paramData.contractUuid = info.uuid;

                        //保存数据到localStorage
                        // localStorage.setItem("paramData", JSON.stringify(paramData));
                        //新建路由
                        // const newWindows = this.$router.resolve({path: "/test-canvas"});
                        //新界面打开
                        // window.open(newWindows.href, "_blank");
                        //调用方法，关闭添加合同弹框
                        // that.clickCancel();
                    }).finally(() => loading.close());
                }).catch(() => {
                    paramData.isCreateElectContract = false;
                    // console.log("线下", paramData);
                    let loading = that.$loading({...loadingOptions});
                    //调用接口创建保租房个人系统合同
                  hetongshengpiGET(paramData).then((res) => {
                        MessageSuccess("生成合同成功");
                        //调用方法，关闭添加合同弹框
                        that.clickCancel(true);
                    }).finally(() => loading.close());
                });
            } else {
                paramData.isCreateElectContract = false;
                console.log("企业", paramData);
                // alert('企业')
                let loading = that.$loading({...loadingOptions});
                //调用接口创建保租房企业系统合同  setUserContract旧

              hetongshengpiGET(paramData).then((res) => {
                    MessageSuccess("生成合同成功");
                    //调用方法，关闭添加合同弹框
                    that.clickCancel(true);
                }).finally(() => loading.close());
            }
        },

        //添加合同窗体关闭
        clickCancel(itr = false) {
            //调用方法刷新合同列表
            this.$emit("handleSearch");
            //重置数据
            this.contractData = {
                leasorIdcard: null, leasorType: null, idType: null, leasorName: null, leasorPhone: null,
                contactName: null, contactPhone: null, addressId: null, eMail: null, signDate: null, rent: 0,
                depositAmount: 0, serviceAmount: 0, bill: true, face: true, faceInfo: null, frontIdcard: null,
                reverseIdcard: null, businessLicense: null, manager: null, keeper: null, startDate: null, endDate: null,
            };
            if(itr){
              // query: {data: {apartmerntName: apartmentName}}
              this.$router.push({name: "approval-process-workorder", });
            }
            //关闭弹框
            this.addDialogVisible = false;
        },

        //人脸头像上传
        faceInfoUploadSuccess({info}) {
            this.contractData.faceInfo = info.uuid;
        },

        //身份证正面上传
        frontIdcardUploadSuccess({info}) {
            this.contractData.frontIdcard = info.uuid;
        },

        //身份证反面上传
        reverseIdcardUploadSuccess({info}) {
            this.contractData.reverseIdcard = info.uuid;
        },

        //营业执照上传
        businessLicenseUploadSuccess({info}) {
            this.contractData.businessLicense = info.uuid;
        },
        handleSuccess({fileList}) {
            //获取全部上传成功图片uuid
            this.imgList = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
            console.log('this.imgList',this.imgList);
        },
        handleRemove({fileList}) {
            //获取全部剩余图片uuid
            this.imgList = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
        },
    },

    watch: {
        zq(value) {
            if (value && value.length > 0 && value.length === 2) {
                let [startDate, endDate] = value;
                this.contractData.startDate = startDate;
                this.contractData.endDate = endDate;
                this.getSignAmount();
            } else {
                this.contractData.startDate = null;
                this.contractData.endDate = null;
            }
        },
        "contractData.leasorIdcard"(value) {
            if (value) if (value.length >= 18) this.getLeasorData(value);
        },
    },
};
</script>
<style lang="scss" scoped>
#canvas-broad {
    margin: 0 auto;
    /*text-align: center;*/
}

#canvas {
    border: 2px solid #ff6700;
    cursor: crosshair;
    /*不能用这种方式给canvas设置宽高*/
    /*width: 600px;*/
    /*height: 300px;*/
}

.btn {
    width: 70px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #aaa; /*去掉<button>默认边框*/
    outline: none; /*去掉<button>选中时的默认边框*/
    cursor: pointer;
}

.input-color {
    width: 70px;
    height: 40px;
    border-radius: 10px;
    border: 0; /*去掉<button>默认边框*/
    outline: none; /*去掉<button>选中时的默认边框*/
}

#image_png {
    width: 300px;
    height: 150px;
    border: 2px solid #ff6700;
    display: block;
    margin: 10px auto;
}

.section {
    margin-top: 10px;
}

.info {
    color: #f0f;
    font-size: 14px;
    line-height: 40px;
}

.bg {
    background: #ff6700;
}

.fw {
    font-weight: 700;
}
</style>
